var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { defineComponent, inject, reactive, ref, computed, onBeforeMount } from 'vue';
import { IonPage, IonContent } from '@ionic/vue';
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2';
import i18n from '@/i18n';
export default defineComponent({
    components: { IonPage: IonPage, IonContent: IonContent },
    setup: function () {
        var _this = this;
        var authStore = inject('authState');
        var configurationStore = inject('configurationStore');
        var addMobileFormData = reactive(configurationStore.state.phone.addMobileFormData);
        var newMobileIsSelected = ref(false);
        var validPrefixList = computed(function () { return authStore.state.validPrefixList; });
        var router = useRouter();
        var goTo = function (url) {
            router.push({ name: url });
        };
        onBeforeMount(function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, authStore.obtainCountry()];
                    case 1:
                        _a.sent();
                        setPrefixDefault();
                        return [2 /*return*/];
                }
            });
        }); });
        var newMobileFormSubmit = function () { return __awaiter(_this, void 0, void 0, function () {
            var data, success;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        configurationStore.state.phone.addMobileFormData.showSuccess = false;
                        configurationStore.state.phone.addMobileFormData.showError = false;
                        if (!(configurationStore.state.phone.addMobileFormData.number != null &&
                            configurationStore.state.phone.addMobileFormData.number.toString().length != 9)) return [3 /*break*/, 1];
                        configurationStore.state.phone.addMobileFormData.showError = true;
                        configurationStore.state.phone.addMobileFormData.errorMessage = 'tester_mobile_not_valid';
                        return [3 /*break*/, 3];
                    case 1:
                        data = {
                            number: configurationStore.state.phone.addMobileFormData.number,
                            prefix: configurationStore.state.phone.addMobileFormData.prefix
                        };
                        return [4 /*yield*/, configurationStore.addMobile(data)];
                    case 2:
                        success = _a.sent();
                        if (success === true) {
                            Swal.fire({
                                icon: 'success',
                                title: i18n.global.t('tester_mobile_validated'),
                                text: i18n.global.t('tester_email_validate_mobile_now'),
                                showConfirmButton: true,
                                confirmButtonText: i18n.global.t('Accept'),
                            }).then(function (result) {
                                if (result.isConfirmed || result.dismiss === Swal.DismissReason.esc || result.dismiss === Swal.DismissReason.backdrop) {
                                    redirect();
                                }
                            });
                        }
                        else if (success === 'registrationMobileFraud') {
                            Swal.fire({
                                icon: 'error',
                                title: i18n.global.t('tester_validate_mobile_error')
                            });
                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                title: i18n.global.t('Error_Try_Again'),
                                text: i18n.global.t('tester_mobile_validation_check'),
                            });
                        }
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        var redirect = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                goTo('verify-mobile');
                return [2 /*return*/];
            });
        }); };
        var setErrorMessage = function (errorCode) {
            switch (errorCode) {
                case 'tester_mobile_not_valid':
                    return i18n.global.t('tester_mobile_not_valid');
                case 'registration_mobile_fraud':
                    return i18n.global.t('tester_duplicate_mobile_error');
                case 'default_error_message':
                default:
                    return i18n.global.t('tester_default_error');
            }
        };
        var setPrefixDefault = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                addMobileFormData.prefix = authStore.state.userData.mobilePrefix;
                return [2 /*return*/];
            });
        }); };
        var addMobileFormValid = computed(function () { return addMobileFormData.prefix && addMobileFormData.number; });
        return {
            addMobileFormData: addMobileFormData,
            newMobileIsSelected: newMobileIsSelected,
            newMobileFormSubmit: newMobileFormSubmit,
            goTo: goTo,
            validPrefixList: validPrefixList,
            addMobileFormValid: addMobileFormValid,
            setErrorMessage: setErrorMessage,
            redirect: redirect
        };
    },
});
